<template>
  <div>
    <div class="row">
      <div class="col-12">
        <c-tab
          type="tabcard"
          :tabItems.sync="tabItems"
          :inlineLabel="true"
          v-model="tab"
          align="left"
        >
          <template v-slot:default="tab">
            <component 
              :is="tab.component"
            />
          </template>
        </c-tab>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'my-page',
  data() {
    return {
      tab: 'vendorDetail',
      tabItems: [
        { name: 'vendorDetail', icon: 'list', label: '상세정보', component: () => import(`${'./vendorDetail.vue'}`) },
        { name: 'vendorAttach', icon: 'info', label: '서류', component: () => import(`${'./vendorAttach.vue'}`) },
      ],
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
    },
  }
};
</script>
